import './App.css';
import { useEffect } from 'react';

function App() {

  useEffect(() => {
    // window.location.replace("https://efizzy.houseofpraise.ca/");
  }, []);


  return (
    <div className="App">
      This is Ignite
    </div>
  );
}

export default App;
